.delete-modal {
  &-window {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: #ffffff99;
  }
  &-container {
    width: 30%;
    height: 20%;
    background-color: #e8f8f5a8;
    display: flex;
    flex-direction: column;
    border: 1px solid #117864;
  }
  &-image {
    background-color: #e8f8f5a8;
    object-fit: contain;
    max-height: 90%;
  }
  &-footer {
    width: 40%;
    display: flex;
    justify-content: space-between;
  }
  &-delete {
    height: 30px;
    width: 80px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #49817d;
    border-radius: 4px;
    color: white;
    border: none;

    &:hover {
      background-color: #345a57;
      cursor: pointer;
    }
  }
  &-header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  &-inner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &-close {
    cursor: pointer;
  }
}
