.admin-panel {
  width: 90%;
  height: 93%;
  &-header {
    padding: 10px 20px;
    font-family: "Crete Round";
    font-style: normal;
    font-size: 42px;
  }
  &-container {
    display: flex;
    width: 100%;
    flex: 1;
    height: 750px;
  }
  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 16px;
    box-sizing: border-box;
    &-actions {
      display: flex;
      flex-direction: row;
      align-items: space-between;
      justify-content: space-between;
      &-item {
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
  &-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }
  }
  &-list-header {
    width: 100%;
    height: 64px;
    padding: 4px;
    box-sizing: border-box;
    font-family: "Crete Round";
    font-style: normal;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    &-action {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: blue;
      cursor: pointer;
    }
  }
  &-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 48px;
    background-color: whitesmoke;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
  &-button-list {
    display: flex;
    flex-direction: row;
  }
  &-button {
    display: flex;
    flex-direction: column;
  }
}
