.user-edit {
  &-window {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: #ffffff99;
  }
  &-container {
    width: 40%;
    background-color: #e8f8f5;
    display: flex;
    flex-direction: column;
    border: 1px solid #117864;
  }
  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 4px 8px;
      box-sizing: border-box;
      &-title {
        width: 100%;

        font-family: "Crete Round";
        font-style: normal;
        font-size: 18px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }
  }
  &-submit {
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 16px 24px;
    justify-content: flex-end;
    &-button {
      width: 64px;
    }
  }
  &-image {
    background-color: #e8f8f5a8;
    object-fit: contain;
    max-height: 90%;
  }
  &-header {
    width: 100%;
    height: 50px;
    display: flex;
    font-family: "Crete Round";
    font-style: normal;
    font-size: 24px;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  &-close {
    cursor: pointer;
  }
}
