.rule-modal {
  &__seperator {
    width: 90%;
    border-bottom: 3px solid #d9d9d9;
  }

  &__module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  &__module-title {
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    flex: 1;
  }

  &__module-text {
    height: 40%;
    padding: 2% 5%;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    overflow-y: auto;
  }
}
