.login-page {
  background-image: url("../../images/login-page.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 3em;
  }

  &__title {
    font-weight: 400;
    font-size: 96px;
    color: #4b3c29;
    font-family: Georgia;
    margin: 50px 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    background: #dcdee0;
    border-radius: 10px;
    padding: 16px;
  }

  &__form-input {
    padding: 1.4vh 0.8vh 0 0;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__pwd-input-container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  &__icon {
    position: absolute;
    right: 16px;
    color: #75757e;
  }

  &__input {
    background: white;
    color: black;
    border-radius: 10px;
    border: none;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    padding-left: 8px;
    width: 100%;
    height: 36px;
  }

  // &__input.clicked {
  //   background: white;
  //   color: black;
  //   border-radius: 10px;
  //   border: none;
  //   height: 6.2vh;
  //   width: 50vh;
  //   margin: 1.3vh;
  //   font-family: "Crete Round";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 20px;
  //   line-height: 26px;
  //   padding-left: 2%;
  // }

  &__login-button {
    // margin-left: 80%;
    // margin-top: 1vh;
    width: 87px;
    height: 60px;
    padding: 10px 16px;
    margin-top: 18px;
    align-self: flex-end;
    justify-self: flex-end;

    font-size: 23px;
    font-family: "Create Round";
    font-weight: 600;
    line-height: 32px;

    background: #49817d;
    border-radius: 4px;
    color: white;
    border: none;

    &:hover {
      background-color: #345a57;
      cursor: pointer;
      border: 1px solid #495546;
    }
  }

  &__module-header {
    display: flex;
    width: 90%;
    flex-direction: row-reverse;
  }

  &__module-body {
    display: flex;
    width: 100%;
  }

  &__module-title {
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 14px;
    text-align: center;
    flex: 1;
  }
}

@media screen and (max-width: 800px) {
  .login-page {
    grid-template-columns: 1fr;
  }
}
