.image-preview {
  &-window {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: #ffffff99;
  }
  &-container {
    width: 70%;
    height: 70%;
    background-color: #e8f8f5a8;
    display: flex;
    flex-direction: column;
    border: 1px solid #117864;
  }
  &-image {
    background-color: #e8f8f5a8;
    object-fit: contain;
    max-height: 90%;
  }
  &-header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  &-close {
    cursor: pointer;
  }
}
