.modal {
  display: flex;
  width: 90%;
  height: 93%;
  background-color: #ffffffbd;
  justify-content: center;
  border-radius: 8px;

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__group-rules {
    border: 3px solid #d9d9d9;
    padding: 2%;
  }

  &__icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px auto;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__module-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #d9d9d9;
    background-color: #f7f9f9;
    flex-grow: 0.9;
    flex-basis: 0;
    flex-shrink: 0;

    /* Enable scrolling for overflow */
    overflow: auto;
  }

  &__seperator {
    width: 95%;
    border-bottom: 3px solid #d9d9d9;
  }

  &__module-title {
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    width: 95%;
  }

  &__module-text {
    //height: 100%;
    padding: 14px 0;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    overflow-y: auto;
    width: 95%;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }
  }

  &__module-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1.1;
    flex-basis: 0;
  }

  &__module-answer-container {
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    margin-top: 24px;
    flex-grow: 1;
  }

  &__module-icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  &__module-answer {
    resize: none;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    border: 3px solid #d9d9d9;
    padding: 8px;

    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    &:focus {
      outline-color: #d9d9d9;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }
  }
  &__module-submit {
    // margin-left: 80%;
    // margin-top: 1vh;
    height: 60px;
    width: 150px;
    padding: 10px 16px;
    margin-top: 8px;
    margin-bottom: 16px;

    font-size: 20px;
    font-family: "Crete Round";
    font-weight: 500;
    line-height: 26px;

    background: #49817d;
    border-radius: 4px;
    color: white;
    border: none;

    &:hover {
      background-color: #345a57;
      cursor: pointer;
    }
  }
  &__module-letter {
    margin-top: 15%;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__module-letter-inner {
    height: 60px;
    padding: 10px 16px;
    margin-top: 8px;
    margin-bottom: 16px;

    font-size: 23px;
    font-family: "Crete Round";
    font-weight: 500;
    line-height: 26px;

    background: #49817d;
    border-radius: 4px;
    color: white;
    border: none;

    &:hover {
      background-color: #345a57;
      cursor: pointer;
    }
  }
  &__module-photos {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  &__module-audio {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  &__module-audio-remove {
    background-color: #ebebeb;
    box-shadow: 0 2px 5px #bebebe;
    border-radius: 20px;
    box-sizing: border-box;
    color: #000;
    width: 40px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
    &-icon {
      padding: 12px;
    }
  }
}
