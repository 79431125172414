.module-photo-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 90%;
  height: 90%;
  background: lightgray;
  cursor: pointer;
  user-select: none;
  color: #666666;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.remove-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 3;
}
