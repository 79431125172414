.module-item {
  display: flex;
  width: 100%;
  text-decoration: none;
  &-forum {
    width: 60%;
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-tag {
      font-size: 14px;
    }
  }
  &-module {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;
    border: 1px solid black;
    border-radius: 4px;
    text-decoration: none;
    padding: 4px 12px;

    margin: 4px 8px;
    width: 100%;
    &-content {
      display: flex;
      width: 90%;
      height: 48px;
      font-family: "Crete Round";
      font-style: normal;
      font-size: 16px;
      color: black;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .module-item-forum {
    width: 40%;
    &-tag {
      display: none;
    }
  }
}
