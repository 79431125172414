.forum-entry {
  margin-top: 8px;
  &-container {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 8px 8px;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #d9d9d9;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 4px;
  }
  &-trash {
    cursor: pointer;
    &:hover {
      color: #dc3645;
    }
  }
  &-body {
    font-family: "Crete Round";
    font-style: normal;
    font-size: 16px;
    margin-bottom: 4px;
  }
  &-comment {
    display: flex;
    justify-content: flex-end;
    font-family: "Crete Round";
    font-size: 12px;
    align-items: center;
  }
  &-child-container {
    display: flex;
  }
  &-child-spacer {
    height: 100%;
    flex: 1;
  }
  &-child {
    flex: 11;
  }
  &__forum-submit {
    // margin-left: 80%;
    // margin-top: 1vh;
    height: 30px;
    width: 100px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    background: #49817d;
    border-radius: 4px;
    color: white;
    border: none;

    &:hover {
      background-color: #345a57;
      cursor: pointer;
    }
  }
}
