.rules {
  display: flex;
  width: 90%;
  height: 93%;
  background-color: #ffffffbd;
  border-radius: 8px;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    padding: 4px 8px;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    margin-bottom: 48px;
  }
  &-body {
    padding: 4px 8px;
    height: 40%;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    overflow-y: auto;
  }
}
