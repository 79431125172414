.module-entry {
  border: 2px solid #d9d9d9;
  margin-top: 8px;
  border-radius: 3px;
  padding: 12px;
  min-width: 95%;
  &-container {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;

    &-body {
      flex: 4;
    }
  }
  &-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #d9d9d9;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 4px;
  }
  &-trash {
    cursor: pointer;
    &:hover {
      color: #dc3645;
    }
  }
  &-body {
    font-family: "Crete Round";
    font-style: normal;
    font-size: 16px;
    margin-bottom: 4px;
  }
  &-photos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 300px;
    height: 20%;
    margin-top: 40px;
    &-thumb {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 150px;
      min-height: 100px;
      cursor: pointer;
      user-select: none;
      color: #666666;
    }
    &-photo {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    &-voice {
    }
    &-comment {
      display: flex;
      justify-content: flex-end;
      font-family: "Crete Round";
      text-decoration: underline;
      font-size: 12px;
      align-items: center;
    }
  }
  &-child-container {
    display: flex;
  }
  &-child-spacer {
    height: 100%;
    flex: 1;
  }
  &-child {
    flex: 11;
  }
  &-children {
    margin-top: 8px;
  }
}
