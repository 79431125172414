.dashboard {
  background-image: url("../../images/dashboard-copy.jpg");
  background-repeat: round;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;

  &__seperator {
    border: 2px solid #d9d9d9;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
}
