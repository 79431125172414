.info {
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 0.5fr 1fr 10fr 0.8fr;
  justify-content: center;
  width: 25%;
  margin: 0 8px;
  overflow-y: scroll;

  &-header-buttons {
    display: flex;
    justify-content: space-around;
  }
  &-header-admin {
    flex: 1;
  }
  &-header-rules {
    flex: 6;
  }

  &__title {
    font-size: 1.4em;
    font-family: "Crete Round";
    font-weight: 600;
    line-height: 32px;
    padding: 4px 10px;
    &-seperator {
      border: 1px solid #d9d9d9;
      height: 0px;
      margin: 0 8px;
      box-sizing: border-box;
    }
  }

  &__rule-button {
    display: flex;
    margin: 4px 8px;
    height: 55px;
    padding: 4px 16px;

    background: #98bc9f;
    border-radius: 4px;
    border: 1px solid #495546;

    font-family: "Crete Round";
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 1;

    color: #000000;

    &:hover {
      background-color: #57948f;
      cursor: pointer;
      border: 1px solid #495546;
    }
    text-decoration: none;
    box-sizing: border-box;
    align-items: center;
  }
  &-logout {
    width: 100%;
    text-decoration: none;
    background-color: #dc3545;

    color: white;
    border: 1px solid black;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #c82333;
    }
  }
}

@media screen and (max-width: 800px) {
  .info {
    &-header-buttons {
      flex-direction: column;
    }
    min-width: 220px;
  }
}
