.mail-modal {
  &__seperator {
    width: 90%;
    border-bottom: 3px solid #d9d9d9;
  }

  &__module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  &__module-title {
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 14px;
    text-align: center;
    flex: 1;
  }
  &__module-body {
    display: flex;
    width: 100%;
  }
  &__module-form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content: right;
    height: 40%;
    padding: 2% 5%;
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    &-textarea {
      width: 100%;
      min-height: 400px;
      resize: none;
      font-size: 20px;
      font-family: "Crete Round";

      &:focus {
        outline-color: #d9d9d9;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
        border-radius: 4px;
      }
    }
    &-submit {
      // margin-left: 80%;
      // margin-top: 1vh;
      height: 60px;
      padding: 10px 16px;
      margin-top: 24px;

      font-size: 20px;
      font-family: "Crete Round";
      font-weight: 300;
      line-height: 32px;

      background: #49817d;
      border-radius: 4px;
      color: white;
      border: 1px solid transparent;

      &:hover {
        background-color: #345a57;
        cursor: pointer;
        border: 1px solid #495546;
      }
    }
  }
}
