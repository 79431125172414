.modal-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 0.1fr 1fr;
  &__back-arrow {
    text-decoration: unset;
    margin-left: 16px;

    font-family: "Crete Round";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: black;

    &:hover {
      cursor: pointer;
      font-size: 26px;
    }
  }
  &__modal-letter {
    display: flex;
    width: 80%;
    height: 95%;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    background-image: url("../../images/letter-background.jpg");
    &__module-answer-container {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      box-sizing: border-box;
      position: relative;
      flex-grow: 1;
    }
    &__module-answer {
      background-color: unset;
      resize: none;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      border: unset;
      padding: 8px;

      font-family: "Crete Round";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;

      &:focus {
        border: 0 none #fff;
        outline: none;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #837a64;
        border-radius: 4px;
      }
    }
    &__module-audio {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    &__module-audio-remove {
      background-color: #ebebeb;
      box-shadow: 0 2px 5px #bebebe;
      border-radius: 20px;
      box-sizing: border-box;
      color: #000;
      width: 40px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      cursor: pointer;
      &-icon {
        padding: 12px;
      }
    }
    &__module-submit {
      // margin-left: 80%;
      // margin-top: 1vh;
      height: 40px;
      width: 130px;
      margin-right: 16px;
      font-size: 23px;
      font-family: "Crete Round";
      font-weight: 500;
      line-height: 26px;

      background: #49817d;
      border-radius: 4px;
      color: white;
      border: none;

      &:hover {
        background-color: #345a57;
        cursor: pointer;
      }
    }
  }
}

// .modal-letter {
//   display: flex;
//   width: 70%;
//   height: 90%;
//   justify-content: center;
//   border-radius: 8px;
//   background-image: url("../../images/letter-background.jpg");
//   &__group-rules {
//     border: 3px solid #d9d9d9;
//     padding: 2%;
//   }
//   &__title {
//     border-bottom: 3px solid #d9d9d9;
//     font-family: "Crete Round";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 34px;
//     line-height: 54px;
//     text-align: center;
//     padding-bottom: 2%;
//   }

//   &__desc {
//     font-family: "Crete Round";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 24px;
//     line-height: 1.6;
//     text-align: left;
//   }

//   &__icons {
//     display: flex;
//     width: 100%;
//     justify-content: space-between;
//     margin: 2% auto;
//   }

//   &__module-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     border: 3px solid #d9d9d9;
//     background-color: #f7f9f9;
//     max-height: 45%;
//   }

//   &__seperator {
//     width: 90%;
//     border-bottom: 3px solid #d9d9d9;
//   }

//   &__module-title {
//     font-family: "Crete Round";
//     font-style: normal;
//     font-weight: 600;
//     font-size: 26px;
//     text-align: left;
//     width: 90%;
//   }

//   &__module-text {
//     height: 50%;
//     padding: 2% 5%;
//     font-family: "Crete Round";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     overflow-y: auto;
//     margin-top: 12px;

//     &::-webkit-scrollbar {
//       width: 8px;
//     }

//     &::-webkit-scrollbar-thumb {
//       background: #cdcdcd;
//       border-radius: 4px;
//     }
//   }

//   &__module-form {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     flex-grow: 1;
//   }

//   &__module-answer-container {
//     width: 100%;
//     height: 100%;
//     padding: 0;
//     box-sizing: border-box;
//     position: relative;
//     margin-top: 24px;
//     flex-grow: 1;
//   }

//   &__module-icon {
//     position: absolute;
//     bottom: 16px;
//     right: 16px;
//   }

//   &__module-answer {
//     resize: none;
//     width: 100%;
//     height: 100%;
//     overflow-y: auto;
//     box-sizing: border-box;
//     border: 3px solid #d9d9d9;
//     padding: 8px;

//     font-family: "Crete Round";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;

//     &:focus {
//       outline-color: #d9d9d9;
//     }

//     &::-webkit-scrollbar {
//       width: 8px;
//     }

//     &::-webkit-scrollbar-thumb {
//       background: #cdcdcd;
//       border-radius: 4px;
//     }
//   }
//   &__module-submit {
//     // margin-left: 80%;
//     // margin-top: 1vh;
//     height: 60px;
//     width: 150px;
//     padding: 10px 16px;
//     margin-top: 8px;
//     margin-bottom: 16px;

//     font-size: 23px;
//     font-family: "Crete Round";
//     font-weight: 500;
//     line-height: 26px;

//     background: #49817d;
//     border-radius: 4px;
//     color: white;
//     border: none;

//     &:hover {
//       background-color: #345a57;
//       cursor: pointer;
//     }
//   }
//   &__module-letter {
//     margin-top: 15%;
//     display: flex;
//     justify-content: center;
//     width: 100%;
//   }
//   &__module-letter-inner {
//     height: 60px;
//     padding: 10px 16px;
//     margin-top: 8px;
//     margin-bottom: 16px;

//     font-size: 23px;
//     font-family: "Crete Round";
//     font-weight: 500;
//     line-height: 26px;

//     background: #49817d;
//     border-radius: 4px;
//     color: white;
//     border: none;

//     &:hover {
//       background-color: #345a57;
//       cursor: pointer;
//     }
//   }
//   &__module-photos {
//     padding: 0px 8px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     flex: 1;
//   }
//   &__module-audio {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     padding: 10px;
//     box-sizing: border-box;
//   }
//   &__module-audio-remove {
//     background-color: #ebebeb;
//     box-shadow: 0 2px 5px #bebebe;
//     border-radius: 20px;
//     box-sizing: border-box;
//     color: #000;
//     width: 40px;
//     display: flex;
//     align-items: center;
//     margin-left: 8px;
//     cursor: pointer;
//     &-icon {
//       padding: 12px;
//     }
//   }
// }
