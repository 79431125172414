.page-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: white;
  opacity: 0.5;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
