.modules {
  width: 100%;

  &__title {
    font-family: "Crete Round";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    padding: 0 8px;

    justify-content: flex-start;
  }
}
